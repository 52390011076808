import * as d3 from 'd3';
export function FilterFetchedData(fetchedData,MaxnumberofDatasetTofetch){
    var tempArrFinal=[]
    fetchedData.filter(item=>{
        var tempArr=[]
        item.data.results.filter(element=>{
            if(tempArr.length<MaxnumberofDatasetTofetch){
            if(element.resource.columns_datatype.length!==0 && element.resource.columns_name.length!==0 && element.classification.domain_tags.length!==0){
                tempArr.push(element)
              }
            }
        })
        tempArrFinal.push({data:tempArr})
    })
    return tempArrFinal
}
export function Additional_dataset_and_attribute_data(fetchedData){
    var Tempset = new Set();
    var attributesTypedict={} // contains the attributes and their data type
    var datasetdescriptionDict={}
    var dtype_occurence={} // contains attr datat type and their occurence
    Object.keys(fetchedData).map((keyword)=>{
        var tempArr=[]
        fetchedData[keyword].map(element=>{
            datasetdescriptionDict[element.resource.name]=element.resource.description
            element.resource.columns_datatype.filter(item=>Tempset.add(item.toLowerCase().replace(/[_-]/g, " ")))
            element.resource.columns_name.filter((item2,i)=>{
                attributesTypedict[item2]=element.resource.columns_datatype[i].toLowerCase().replace(/[_-]/g, " ")
            })
        })
    })
    var dtypeArray=Array.from(Tempset)
    //------
    var myColor = ['#e41a1c','#377eb8','#4daf4a','#984ea3','#ff7f00','#ffff33','#a65628','#f781bf','#999999']
    var dtype_colorDict={}
    dtypeArray.map((item,i)=>dtype_colorDict[item]=myColor[i])
    var attributesTypecolorDict={};
    Object.keys(attributesTypedict).map(item=>attributesTypecolorDict[item]=dtype_colorDict[attributesTypedict[item]])
    //------------------
    Object.values(attributesTypedict).map(val=>{
        if(dtype_occurence[val]>0){dtype_occurence[val]=dtype_occurence[val]+1}
        else{dtype_occurence[val]=1}
    })
    //--
    return {attributesTypecolorDict:attributesTypecolorDict,dtype_colorDict:dtype_colorDict,datasetdescriptionDict:datasetdescriptionDict,dtype_occurence:dtype_occurence}
}
export function categorizedDatasets(fetchedData){
    var arr=[]
    var keys=Object.keys(fetchedData)
    var tempDict={}
    keys.map((keyword)=>{
        var tempArr=[]
        fetchedData[keyword].map(element=>{
                if(!arr.includes(element.resource.name)){arr.push(element.resource.name);tempArr.push(element.resource.name)}
        })
        tempDict[keyword]=tempArr
    })
    return tempDict;
}
export function datasetWithTagArray(fetchedData){
    var keys=Object.keys(fetchedData)
    var tempDict={}
    keys.map((keyword)=>{
        fetchedData[keyword].map(element=>{
                tempDict[element.resource.name]=element.classification.domain_tags
        })
    })
    return tempDict;
}
//-----------------------------------------------------------------
export function dict_of_tags_basedon_keyword(fetchedData){
    var keys=Object.keys(fetchedData)
    var tempDict={}
    var tempSet=new Set()
    keys.map((keyword)=>{
        var tempArr=[]
        fetchedData[keyword].map(element=>{
                //tempSet=new Set([ ...tempSet, ...element.classification.domain_tags])
                element.classification.domain_tags.forEach(element => {
                    if(!tempSet.has(element)){
                        tempArr.push(element)
                        tempSet.add(element)
                    } 
                });
        })
        tempDict[keyword]=tempArr;
        //tempDict[keyword]=[...new Set(tempArr)];
    })
    return tempDict;
}
//-----------------------------------------------------------------
export function tag_count(fetchedData){
    var keys=Object.keys(fetchedData)
    var tempDict={}
    keys.map((keyword)=>{
        fetchedData[keyword].map(element=>{
                element.classification.domain_tags.map(tag=>{
                    if(tempDict[tag]>0){tempDict[tag]=tempDict[tag]+1}
                    else{tempDict[tag]=1}
                })
        })
    })
    return tempDict;
}
//-----------------------------------------------------------------
export function datasets_with_Attributes_dict(fetchedData){
    var keys=Object.keys(fetchedData)
    var tempDict={}
    keys.map((keyword)=>{
        fetchedData[keyword].map(element=>{
                tempDict[element.resource.name]=element.resource.columns_name
        })
    })
    return tempDict
}
//-----------------------------------------------------------------
export function datasets_link(fetchedData){
    var keys=Object.keys(fetchedData)
    var tempDict={}
    keys.map((keyword)=>{
        fetchedData[keyword].map(element=>{
            //console.log(element.resource.name,element.permalink.replace('/d/','/resource/'))
            tempDict[element.resource.name]=element.permalink.replace('/d/','/resource/')
        })
    })
    return tempDict
}