import React, { Component } from 'react'
import * as d3 from 'd3';
class ParentHeatmap extends Component {
   constructor(props){
      super(props)
      this.childKey = 0;
      this.createHeatMap = this.createHeatMap.bind(this)
      this.prep = this.prep.bind(this)
      this.X_charsize=50;
      this.dataset_bar_distance=1;
   }
    shouldComponentUpdate(nextProps, nextState) {
      return ((this.props.categorizedDatasets!==nextProps.categorizedDatasets)) || (JSON.stringify(this.props.matrixdata)!==JSON.stringify(nextProps.matrixdata));
   }
//--------------------------------Prepare for HeatMap here
   prep(mydata,filtered_shared_attributes){
       
    var arr=[]
    for (var i=0;i<mydata.datasets.length;i++){
        for(var j=0;j<filtered_shared_attributes.length;j++){
            var arr2=[];
            arr2.push(mydata.datasets[i]);
            arr2.push(filtered_shared_attributes[j])
            arr2.push(mydata.matrix[i][j]);
            arr.push(arr2);
        }
    }
    return arr;
}
//------------CreateHeatMap starts here
createHeatMap() {
  var animation_duration=1000
  var multiplier=20; // multiplier defines the cell size
  var myWidth=(this.props.filtered_shared_attributes.length*multiplier) + 520+multiplier
  var myHeight=(this.props.matrixdata.datasets.length*multiplier) + 150+multiplier
//---------------------------------------------------------------------------------
     var self=this;
    const node = "."+this.props.myclass
    // change the left margin so that dataset names are visible
      var margin = {top: 150, right: 140, bottom: 20, left: 400};
    var width = myWidth - margin.right - margin.left,
    height = myHeight - margin.top - margin.bottom;
    var data=this.prep(this.props.matrixdata,this.props.filtered_shared_attributes)
    var cloned_data = JSON.parse(JSON.stringify(data))
    // duplicate values creates problem when trancated
    var x_elements = d3.set(cloned_data.map(function( d ) { 
      return d[1].substring(0,self.X_charsize); } )).values();
    var y_elements = d3.set(data.map(function( d ) { return d[0] } )).values();
// Ordinal Scaling for X axis
    var xScale = d3.scaleBand()
      xScale.domain(x_elements).range([0, width]);
    var xAxis = d3.axisTop(xScale)
      .tickFormat(function (d) {
          return d;
      })
      //const x = d3.scaleLinear();
      //const xAxis = d3.axisBottom(x);
//Ordinal Scaling for Y axis
    var yScale = d3.scaleBand()
      .domain(y_elements)
      .range([0, height]);
    var yAxis = d3.axisLeft(yScale)
      .tickFormat(function (d) {
          return d;
      })  
//-------------------------------------------------------------------------------------- d3 Select starts here
    var svg = d3.select(node).attr("width", width + margin.left + margin.right).attr("height", height + margin.top + margin.bottom)
    var gMatrix=d3.select(node).selectAll('.group_xy_axis').data([null])
    gMatrix=gMatrix.enter().append('g').merge(gMatrix).attr("class","group_xy_axis").attr("transform", "translate(" + margin.left + "," + margin.top + ")"); // Append only one since the array contains 1 element
    var gMatrixData=gMatrix.selectAll('rect').data(data)
    var gMatrixExit=gMatrixData.exit().remove() // make sure to call the data
    var gMatrixEnter=gMatrixData.enter() // make sure to call the data
    gMatrixData.merge(gMatrixEnter)
//-------------------------------------------------------------------------------------- Main view starts here
          var rects=gMatrixData.enter().append('rect')
          .merge(gMatrixData)          
          .attr('class', 'cell')
          .attr('id',(d,i)=>"cell"+i)
          .attr('width', width/(this.props.filtered_shared_attributes.length)-1)
          .attr('height', height/(this.props.matrixdata.datasets.length)-1)
          .attr('y', function(d) { return yScale(d[0]); })
          .transition().duration(animation_duration)
          .attr('x', function(d) { return xScale(d[1].substring(0,self.X_charsize)); })
          .attr('fill', (d,i)=>{
              return d[2]!=='n'? 'rgb(158,154,200)':"#bdbdbd";
          })
          .attr('fill', (d,i)=>{
            if(this.props.categorizedDatasets!= null){
              //d[0] returns datasetname for each cell
              var temp_color;
              var self=this;
              Object.keys(this.props.categorizedDatasets).forEach(function(k, i) {
                if(self.props.categorizedDatasets[k].includes(d[0])){
                temp_color=self.props.cat_colors[k];
                }
            });
              return d[2]!=='n'? temp_color:"#bdbdbd";
            }
            else{
              return d[2]!=='n'? 'rgb(158,154,200)':"#bdbdbd";
            }
            
          })
//------------------------------------------- X axis
var Height_of_bar=margin.top;
const Xaxisgroup = svg.selectAll('.myX').data([0]) // g has all the updated data 
Xaxisgroup.exit().remove()
var myXaxis=Xaxisgroup.enter().append("g")
.attr("fill",(d)=>{
//console.log(d,d.length,self.props.Additional_dataset_and_attribute_data.attributesTypecolorDict[d])
return self.props.Additional_dataset_and_attribute_data.attributesTypecolorDict[d]
})
.merge(Xaxisgroup)
.attr("class", "myX x axis").attr("transform", "translate(" + margin.left + "," + margin.top + ")") // Append only one since the array contains 1 element
.transition().duration(animation_duration).call(xAxis).selectAll('text').text((d,i)=>d.length>13?d.substring(0,13)+'..':d)
.attr("fill",(d)=>{
  return 'black'
  //return self.props.Additional_dataset_and_attribute_data.attributesTypecolorDict[0]
})

d3.select(".myX").selectAll('text')
.on('click',(d,i)=> {
    this.props.xy_axis_clickhandler(d3.event,d,i,'x',d);
})
.on('contextmenu',(d,i)=> {
  this.props.contextmenuHandler(d3.event,"update_child") 
  })
.attr("transform", function (d) {
    return "rotate(-90)";    
})
.attr("id",(d,i)=> d).style("text-anchor", "start")
.attr("dx",(d,i)=> {
  return Height_of_bar-92
})
.attr("dy", "1.1em")
.attr("cursor", "pointer")
.attr("fill",(d)=>{
  return "#303030";
});    
//X axis ends here
//------------------------------------------- Attributes Bars start here (This is for the right barchart)
var mydata2=this.props.first_bar_data
var Height_of_bar=margin.top;
var yscale2 = d3.scaleLinear()
    .domain([d3.min(mydata2[1]),d3.max(mydata2[1])])
    .range([20, Height_of_bar-100]); 
//---Height_of_bar - makes space for text
var xScale_for_attributes_bars = d3.scaleBand()
  .domain(mydata2[0])
  .range([0, width]);
var svg_attribute_bars_container=svg.selectAll('.Xsvg').data([0])
svg_attribute_bars_container.exit().remove()
svg_attribute_bars_container=svg_attribute_bars_container.enter().append('svg').merge(svg_attribute_bars_container)
.attr('class','Xsvg').attr("x",margin.left).attr("y",0-this.dataset_bar_distance).attr("width",width).attr("height",Height_of_bar).attr("transform", "translate(" + 0 + "," + -6 + ")");

var XrectBars=svg_attribute_bars_container.selectAll('rect').data(mydata2[1])
XrectBars.exit().remove()
XrectBars=XrectBars.enter().append('rect').merge(XrectBars)
.attr("width",width/(this.props.filtered_shared_attributes.length)-1)
.attr("y",function(d){
  return Height_of_bar-(yscale2(d));
})
.attr('height',(d,i)=>yscale2(d)).attr("fill","#5f89ad")
.transition()
.duration(animation_duration)
.attr('x',(d,i)=>xScale_for_attributes_bars(this.props.first_bar_data[0][i]))

//-----frequency text
svg_attribute_bars_container=svg_attribute_bars_container.selectAll('text').data(mydata2[1])
svg_attribute_bars_container.exit().remove()
svg_attribute_bars_container.enter().append('text').merge(svg_attribute_bars_container).attr("width",width/(this.props.filtered_shared_attributes.length)-.8)
.attr('height',(d,i)=>yscale2(d))
.attr("fill","white")
.text((d)=>d)
.attr("y",Height_of_bar-4)
.attr("font-size",12)
.transition()
.duration(animation_duration)
.attr('x',(d,i)=>xScale_for_attributes_bars(this.props.first_bar_data[0][i])+6-(d.toString().length*2))
//--Attributes Bars end

//------------------------------------------- Datasets Bar start here (This is for the left barchart)
var marginLeft=margin.left
var number_of_attr_in_dataset=[]
var dataset_names=[]
for(var key in this.props.dataset_bar_data){
  number_of_attr_in_dataset.push(this.props.dataset_bar_data[key].length)
  dataset_names.push(key)
}
var xScale_for_datasets_bar = d3.scaleLinear()
  .domain([d3.min(number_of_attr_in_dataset),d3.max(number_of_attr_in_dataset)])
  .range([20, marginLeft - 320]);
//-----rectangles
var svg_datasets_bars_container=svg.selectAll('.Ysvg').data([0])
svg_datasets_bars_container.exit().remove()
svg_datasets_bars_container=svg_datasets_bars_container.enter().append("svg").merge(svg_datasets_bars_container)
.attr("class", "Ysvg").attr("x",0).attr("y",margin.top).attr("width",marginLeft).attr("transform", "translate(" + -6 + "," + 0 + ")");

var YrectBars=svg_datasets_bars_container.selectAll('rect').data(number_of_attr_in_dataset)
YrectBars.exit().remove()
YrectBars=YrectBars.enter().append('rect').merge(YrectBars)
.attr('x',(d)=> (margin.left - xScale_for_datasets_bar(d))+xScale_for_datasets_bar(d))
.attr("width",(d)=>xScale_for_datasets_bar(d)-this.dataset_bar_distance) // -2 makes the bars 2 unit further
.attr('x',(d)=> margin.left - (xScale_for_datasets_bar(d)-0))
.attr("height",height/(this.props.matrixdata.datasets.length)-1)
.attr("fill","#5f89ad")
.transition()
.duration(animation_duration)
.attr("y",(d,i)=>yScale(dataset_names[i]))
//-----frequency text
var Ytext=svg_datasets_bars_container.selectAll('text').data(number_of_attr_in_dataset)
Ytext.exit().remove()
Ytext.enter().append('text').merge(Ytext)
.attr('x',(d,i)=>marginLeft - (15+d.toString.length*5))
.attr("font-size",12)
.transition()
.duration(animation_duration)
.attr("y",(d,i)=>yScale(dataset_names[i])+15)
.text((d)=>d)
.attr("fill","white")
//-Datasets Bar ends here
//------------------------------------------- Y Axis
const Yaxisgroup = svg.selectAll('.myY').data([0]) // g has all the updated data 
Yaxisgroup.exit().remove()
Yaxisgroup.enter().append("g").merge(Yaxisgroup)
.attr("class", "myY y axis").attr("transform", "translate(" + margin.left + "," + margin.top + ")") // Append only one since the array contains 1 element
.transition().duration(animation_duration).call(yAxis).selectAll('text').attr("x",-82).text((d,i)=>d.length>50?d.substring(0,50)+'...':d)
d3.select('.myY').selectAll('text')
      .on('click',(d,i)=> {
        this.props.xy_axis_clickhandler(d3.event,d,'index','y')
        })
      .on('contextmenu',(d,i)=> {
        this.props.contextmenuHandler(d3.event) 
        })
      .attr("cursor", "pointer")
      
      .attr("id",(d)=> d)
      .on('mouseover', function (d, i) {
        d3.select(this).transition()
             .duration('50')
             .attr('opacity', '.85');
        div.transition()
             .duration(50)
             .style("opacity", 1);
        div.html(self.props.Additional_dataset_and_attribute_data.datasetdescriptionDict[d])
             .style("left", (d3.event.pageX+25) + "px")
             .style("top", (d3.event.pageY-20) + "px");
   })
   .on('mouseout', function (d, i) {
        d3.select(this).transition()
             .duration('50')
             .attr('opacity', '1');
        div.transition()
             .duration('50')
             .style("opacity", 0);
   });

  var div = d3.select("body").append("div")
  .attr("class", "tooltip-donut")
  .style("opacity", 0);
//----- Y axis ends here
d3.selectAll('.domain,.tick line').remove() 

var temp_legend=svg.selectAll('.legend').data([0])
temp_legend.exit().remove()
/*
var legend=temp_legend.enter().append('svg').merge(temp_legend).attr('width',400).attr('height',200).attr('class','legend')
legend.selectAll('rect').data(Object.keys(this.props.Additional_dataset_and_attribute_data.dtype_colorDict)).enter().append('rect').attr("width",50).attr("height",15)
.attr("fill",'grey')
//.attr("fill",(d,i)=>self.props.Additional_dataset_and_attribute_data.dtype_colorDict[d])
.attr("y",(d,i)=>(i*20)+30).attr("x",10)
legend.selectAll('text').data(Object.keys(this.props.Additional_dataset_and_attribute_data.dtype_colorDict)).enter().append('text').text(d=>d+"("+self.props.Additional_dataset_and_attribute_data.dtype_occurence[d]+")").attr("y",(d,i)=>(i*20)+43).attr("x",65)
.on('click',(d,i)=>{
  var arr=Object.keys(self.props.Additional_dataset_and_attribute_data.attributesTypecolorDict).filter(item=>{
    if(self.props.Additional_dataset_and_attribute_data.attributesTypecolorDict[item]===self.props.Additional_dataset_and_attribute_data.dtype_colorDict[d]){return item} 
  })
  self.props.data_type_filter_Handler("NA",arr)
  //console.log(arr)
  console.log(self.props.Additional_dataset_and_attribute_data)
  //console.log(self.props.Additional_dataset_and_attribute_data.dtype_colorDict[d])
})
*/
}
classAdder = (id,class_name)=>{
  var element = document.getElementById(id);
  element.classList.add(class_name);
  }
classRemover = (id,class_name)=>{
    var element = document.getElementById(id);
    element.classList.remove(class_name);
}
//CreateMap ends here
componentDidMount() {
  this.props.tag_clickhandler("default_id")
  this.createHeatMap()
}
componentDidUpdate() {
  this.createHeatMap()
}
render() 
{
    return(
      <div style={{display:"block",minWidth:'100%'}}>
        <svg className={this.props.myclass} key={this.props.myclass}>
        <foreignobject class="node" x="46" y="22" width="100" height="100">
                <div>I'm a div inside a SVG.</div>                
        </foreignobject>
        </svg>
      </div>
    )
}
}
export default ParentHeatmap