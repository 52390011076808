import React, { Component } from 'react'
import Header from "./Components/Header";
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {connect} from "react-redux";
import TreeMap from './Components/Treemap'
import ParentHeatmap from './Components/ParentHeatmap';
import ChildHeatmap from './Components/ChildHeatmap';
import Merge from './Components/Merge';
import * as MatrixAlgorithms from "./Algorithms/MatrixAlgorithms";
import * as algorithms from './Algorithms/algorithms';
import Button from '@material-ui/core/Button';
class App extends Component {
  constructor(props){
    super(props)
    this.state = {
      viewgrouptracker:2,only_shared_attributes:[],clickedA:[],created_grouped_datasets:null,child_groups_ready_to_vis:[],key:0,matrixdata:{},sorted_groups:[],datasets_with_Attributes: {},popupdata:"",popupdata2:"",file:{},modal: false,modal2:false,modal3:false,
      clicked_group:[],clicked_att:[],corr_elements:[],correlation_data:{},mutual_info_elements:[],mutual_info_data:{},kl_elements:[],
      kl_data:{},prob_dist_elemetns:[],prob_dist_data:{},loader:true,loading_msg:["Successfull","Failed","Failed","Failed"],ParentHeatmap_ready_to_vis:null,all_datasets:"false",first_bar_data:null,first_bar:null,upload_status:"null",dataset_bar_data:null,dataset_bar:null,
      menu_toggler:null,treemapdata:null,treemap_to_vis:null,keyword_to_search:[],udpdate_matrix_button_state:null,clicked_tag_Array:[],datasets_with_tag_array:null,datasets_basedon_tags:null, keyword_based_datasetname_array:null,dict_for_keyword_based_matrix_grouping:{},dataset_array_to_merge:[],search_datasets_func_loader:false,
      dict_to_hold_dypes:null,split:"default",data_type_filtered_array:[],merge_state:false,
    }
  }
  componentDidMount(){
    //this.props.clicked_tag_Array.map(id=>this.classAdder(id,"treemap_class"))
  }
// The id is the tag name here
//---------------------------------------------------------------------------------------------------------------------------------------Tag clickHandler starts here
tag_clickhandler=(id)=>{
var clicked_tag_Array=this.props.clicked_tag_Array  
if(clicked_tag_Array.includes(id)){
    this.classRemover(id,"treemap_class")
    clicked_tag_Array=clicked_tag_Array.filter(function(value, index, arr){
      if(value!=id){
        return value;
      }
    });
  }else{
    if(id!="default_id"){this.classAdder(id,"treemap_class");clicked_tag_Array.push(id)}
}
this.props.set_clicked_tag_Array(clicked_tag_Array)
var tag_keyword_based_datasets_dict=MatrixAlgorithms.myfunc(this.props.datasetWithTagArray,clicked_tag_Array,this.props.categorizedDatasets)
this.props.set_datasets_based_onTagclicks(tag_keyword_based_datasets_dict)
var datasets_with_Attributes_dict=MatrixAlgorithms.datasets_with_AttributesMaker_forTagsclicks(tag_keyword_based_datasets_dict,this.props.datasets_with_Attributes_original)
var datasets_with_Attributes_dictFilterByoccurence=MatrixAlgorithms.MatrixData(datasets_with_Attributes_dict,this.props.Min_numberofattribute_occurence).filteredDatasetswithAttributes
this.props.set_datasets_with_Attributes(datasets_with_Attributes_dictFilterByoccurence) 
}
//---------------------------------------------------------------------------------------------------------------------------------------xy_axis_clickhandler starts here
xy_axis_clickhandler=(event,id,index,axis,attr)=>{ // id is the name of attribute/dataset
  if(event=='set_default'){this.setState({clickedA:[]});this.setState({split:"default"});return}
  event.preventDefault();
if(axis=='y'){ // check if the clicked axis is y axis
  if(this.props.clicked_datasets.includes(id)){ // check if the array already includes the name
//-Remove attributes from array
   array_to_set_clicked = this.props.clicked_datasets.filter(function(e) { return e !== id })
   this.classRemover(id,'textcolor');
   this.classAdder(id,'textcolor_unclick')
   this.props.set_clicked_datasets(array_to_set_clicked)
   this.setState({dataset_array_to_merge:array_to_set_clicked})    
  }
//-Add attributes from array
  else{
    var array_to_set_clicked=this.props.clicked_datasets;
    this.classAdder(id,'textcolor')
    array_to_set_clicked.push(id)
    this.setState({dataset_array_to_merge:array_to_set_clicked})
    //console.log(array_to_set_clicked)
    this.props.set_clicked_datasets(array_to_set_clicked)
    console.log(this.props.clicked_datasets)
  }
}
//-Handle (x axis) attribute clicks
else if(event.type==="click" & this.state.clickedA.includes(attr)){ // check if the event type is onclick and clickedA includes the index
//-To remove attributes from array
    array_to_set_clicked = this.state.clickedA.filter(function(e) { return e !== attr })
    this.classRemover(id,'textcolor');
    this.classAdder(id,'textcolor_unclick')
    this.setState({clickedA:array_to_set_clicked})
}
//-
else if(event.type=="click"){
  var array_to_set_clicked=this.state.clickedA;
    this.classAdder(id,'textcolor')
    array_to_set_clicked.push(attr)
    this.setState({clickedA:array_to_set_clicked})
  }
  
}
//---------------------------------------------------------------------------------------------------------------------------------------
data_type_filter_Handler=(types,data_type_filtered_array)=>{
  this.setState({data_type_filtered_array:data_type_filtered_array})
  console.log(types,data_type_filtered_array)
}

classAdder = (id,class_name)=>{
  var element = document.getElementById(id);
  element.classList.add(class_name);
  }
classRemover = (id,class_name)=>{
    var element = document.getElementById(id);
    element.classList.remove(class_name);
}
  render() {
    if(this.props.datasets_with_Attributes_dict!==null){ 
      var groupdata=this.groupMaker()
    }   
    const { classes } = this.props;
    return (
        <Grid container spacing={1}>
          <Grid classes={{root:this.props.classes.headerGridRoot}} item xs={12}><Header tag_clickhandler={this.tag_clickhandler} xy_axis_clickhandler={this.xy_axis_clickhandler}></Header></Grid>
          {
          this.props.tag_count!==null?<TreeMap key={JSON.stringify(this.props.tag_count)} colors={this.props.keyword_colors} tag_clickhandler={this.tag_clickhandler} 
          tag_count={this.props.tag_count} mydata={this.props.dict_of_tags_basedon_keyword} width={500}></TreeMap>:null
          }         
          <Button onClick={()=>this.setState({split:"default"})}>default</Button>
          <Button onClick={()=>this.setState({split:"split_by_keyword"})}>Split By Keyword</Button>
          {this.state.clickedA.length>0?<Button onClick={()=>this.setState({split:"create_group"})}>Create Groups</Button>:null}
          {
          (this.props.FetchedData!==null) && (this.props.clicked_tag_Array.length>0)?Object.values(groupdata).map((matrixdata,i)=>{
          return i==0?
          <ParentHeatmap tag_clickhandler={this.tag_clickhandler} myclass={"parent"+i} cat_colors={this.props.keyword_colors} key={"key"+i} contextmenuHandler={(event)=>this.contextmenuHandler(event)} 
          xy_axis_clickhandler={this.xy_axis_clickhandler} matrixdata={matrixdata.matrixdata} first_bar_data={matrixdata.data.first_bar_Arr} dataset_bar_data={matrixdata.dataset_bar_data} 
          filtered_shared_attributes={matrixdata.data.filtered_shared_attributes} categorizedDatasets={this.props.categorizedDatasets} Additional_dataset_and_attribute_data={this.props.Additional_dataset_and_attribute_data} data_type_filter_Handler={this.data_type_filter_Handler}/>:
          <ChildHeatmap myclass={"parent"+i} cat_colors={this.props.keyword_colors} key={"key"+i} contextmenuHandler={(event)=>this.contextmenuHandler(event)} 
          xy_axis_clickhandler={this.xy_axis_clickhandler} matrixdata={matrixdata.matrixdata} first_bar_data={matrixdata.data.first_bar_Arr} dataset_bar_data={matrixdata.dataset_bar_data} 
          filtered_shared_attributes={matrixdata.data.filtered_shared_attributes} categorizedDatasets={this.props.categorizedDatasets} Additional_dataset_and_attribute_data={this.props.Additional_dataset_and_attribute_data}/>
        }):null
          }
          <Merge merge_state={this.props.merge_state} key={this.props.merge_state}></Merge>
        </Grid>
    )
  }
  contextmenuHandler=(event)=>{
    event.preventDefault()
    this.props.set_merge_state(true)
  }
  groupMaker=()=>{
    //this.props.datasets_with_Attributes_dict contains selected datasets by tag clicks so it has datasets from all keywords
    var data=MatrixAlgorithms.MatrixData(this.props.datasets_with_Attributes_dict,this.props.Min_numberofattribute_occurence)
    
    if(this.state.data_type_filtered_array.length>0){data.filtered_shared_attributes = this.state.data_type_filtered_array}
    //data.filtered_shared_attributes
    var tempGroup={}
    var split=this.state.split;
    if(split=="split_by_keyword"){
      Object.keys(this.props.categorizedDatasets).map(keyword=>{
        //console.log(this.props.categorizedDatasets[keyword]) // array containing datasets by category
        var temp_datasets_with_Attributes_dict={}
        Object.keys(this.props.datasets_with_Attributes_dict).filter(item=>{
          if(this.props.categorizedDatasets[keyword].includes(item)){temp_datasets_with_Attributes_dict[item]=this.props.datasets_with_Attributes_dict[item]}
        })
        var matrixdata=algorithms.matrixgen(data.filtered_shared_attributes,temp_datasets_with_Attributes_dict); // filteredDatasetswithAttributes contains the datasets filtered by the attributes occurence
        tempGroup[keyword]={matrixdata:matrixdata,data:data,dataset_bar_data:temp_datasets_with_Attributes_dict}
      })
      return tempGroup
    }
//---------------------------------------------------------------------------
    if(split=="create_group" && this.state.clickedA.length>0){
      var myindex=this.state.clickedA;
      var index=myindex.map(item=>data.filtered_shared_attributes.indexOf(item))
      console.log(myindex)
      console.log(data.filtered_shared_attributes.indexOf('Zip Code'))
      var combination2=algorithms.generateAllBinaryStrings(index.length, new Array(index.length), 0)
      var matrix3=algorithms.matrixgen3(data.filtered_shared_attributes,data.filteredDatasetswithAttributes)
      var grouped_datasets=algorithms.grouping_based_on_combination3(combination2,matrix3,index);
      grouped_datasets.map((group,i)=>{
        var temp_datasets_with_Attributes_dict={}
        group.filter(item=>{temp_datasets_with_Attributes_dict[item]=data.filteredDatasetswithAttributes[item]})
        var matrixdata=algorithms.matrixgen(data.filtered_shared_attributes,temp_datasets_with_Attributes_dict);
        tempGroup["group"+i]={matrixdata:matrixdata,data:data,dataset_bar_data:temp_datasets_with_Attributes_dict}
      })
      console.log(tempGroup)
      return tempGroup;
      //return {group1:{matrixdata:matrixdata,data:data,dataset_bar_data:data.filteredDatasetswithAttributes}}
    }
    var data=MatrixAlgorithms.MatrixData(this.props.datasets_with_Attributes_dict,this.props.Min_numberofattribute_occurence) // datasets_with_Attributes_dict contains the datasets with attributes not filtered
    if(this.state.data_type_filtered_array.length>0){
      var tempArr=data.filtered_shared_attributes.filter(item=>this.state.data_type_filtered_array.includes(item))
      console.log(tempArr)
      data.filtered_shared_attributes = tempArr
    }
    var matrixdata=algorithms.matrixgen(data.filtered_shared_attributes,data.filteredDatasetswithAttributes); // filteredDatasetswithAttributes contains the datasets filtered by the attributes occurence
    return {group1:{matrixdata:matrixdata,data:data,dataset_bar_data:data.filteredDatasetswithAttributes}}
  }
}
//-------------
const styles = theme => ({
  headerGridRoot:{
  },
});
//----------
const maptstateToprop=(state)=>{
  return{
    ctr:state.counter,
    Min_numberofattribute_occurence:state.Min_numberofattribute_occurence,
    FetchedData:state.FetchedData,
    dict_of_tags_basedon_keyword:state.dict_of_tags_basedon_keyword,
    tag_count:state.tag_count,
    datasetWithTagArray:state.datasetWithTagArray,
    categorizedDatasets:state.categorizedDatasets,
    datasets_with_Attributes_dict:state.datasets_with_Attributes_dict,
    keyword_colors:state.keyword_colors,
    datasets_with_Attributes_original:state.datasets_with_Attributes_original,
    Additional_dataset_and_attribute_data:state.Additional_dataset_and_attribute_data,
    clicked_tag_Array:state.clicked_tag_Array,
    datasets_link:state.datasets_link,
    clicked_datasets:state.clicked_datasets,
    merge_state:state.merge_state,
  }
}
const mapdispatchToprop=(dispatch)=>{
  return{
    add:(val)=>dispatch({type:"add",value:val}),
    setFetchedData:(d)=>dispatch({type:"FetchedData",value:d}),
    set_datasets_based_onTagclicks:(d)=>dispatch({type:"datasets_based_onTagclicks",value:d}),
    set_datasets_with_Attributes:(d)=>dispatch({type:"datasets_with_Attributes_dict",value:d}),
    set_clicked_tag_Array:(d)=>dispatch({type:"clicked_tag_Array",value:d}),
    set_clicked_datasets:(d)=>dispatch({type:"clicked_datasets",value:d}),
    set_merge_state:(d)=>dispatch({type:"merge_state",value:d}),
  }
}
export default connect(maptstateToprop,mapdispatchToprop)(withStyles(styles)(App));