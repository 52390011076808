import React from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import portals_json from './portals_json.json';
import {connect} from "react-redux";
import { ListItemSecondaryAction } from '@material-ui/core';

function CheckboxLabels(props) {
  
  const handleChange = (event) => {
    if(props.Addedportals.includes(event.target.name))
    {
      var temp=props.Addedportals.filter(item=>item!=event.target.name)
      props.Addportals(temp)
    }  
    else{
      props.Addportals([...props.Addedportals,event.target.name])
    }
  };
  return (
    <FormGroup row>
      {Object.keys(portals_json).map(item=>{
      return <FormControlLabel
        control={<Checkbox checked={props.Addedportals.includes(item)} onChange={handleChange} name={item} />}
        label={item}
      />})}
    </FormGroup>
  );
}
const maptstateToprop=(state)=>{
    return{
      Addedportals:state.Addedportals,
    }
  }
  const mapdispatchToprop=(dispatch)=>{
    return{
      Addportals:(val)=>dispatch({type:"Addportals",value:val}),  
    }
  }
export default connect(maptstateToprop,mapdispatchToprop)(CheckboxLabels);
  

