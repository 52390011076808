import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import Popover from '@material-ui/core/Popover';
import Drawer from '@material-ui/core/Drawer';
import Mydrawer from './Mydrawer'
import Portals from './Portals'
import portals_json from './portals_json.json';
import {connect} from "react-redux";
import $ from 'jquery';
import * as InitialAlgorithms from "../Algorithms/InitialAlgorithms";
import * as MatrixAlgorithms from "../Algorithms/MatrixAlgorithms";
import axios from "axios";
function Header(props) {
  const classes = useStyles();
  const [keywords, setKeywords] = React.useState(["Education","Housing"]);
  const fetchData=()=>{
    console.log('public_mixed')
    props.xy_axis_clickhandler('set_default')
    props.set_datasets_based_onTagclicks(null)
    var kwords=keywords;
    var keyword_temp_colors=['#66c2a5','#fc8d62','#8da0cb','#e78ac3','#a6d854',"#fb9a99"]
    var keyword_colors_dict={}
    keywords.map((word,i)=>{
      return keyword_colors_dict[word]=keyword_temp_colors[i]
    })
    props.set_keyword_colors(keyword_colors_dict)
    //var domains="";
    var domains=props.Addedportals.length>0?portals_json[props.Addedportals[0]]:"data.cityofnewyork.us";
    if(kwords===null){alert("Please type your keywords");return}
    var a=kwords.map(item=>{
      return axios.get("https://api.us.socrata.com/api/catalog/v1?column_names="+item+"&limit="+props.MaxnumberofDatasetTofetch*2)
      //http://api.us.socrata.com/api/catalog/v1?column_names=Permit
      //return axios.get("http://api.us.socrata.com/api/catalog/v1?only=datasets&q="+item+"&domains="+domains+"&offset=0&limit="+props.MaxnumberofDatasetTofetch*2)
    })
    axios.all(a).then(
      axios.spread((...allData)=>{
        var FetchedData={}
        var FilteredallData=InitialAlgorithms.FilterFetchedData(allData,props.MaxnumberofDatasetTofetch) // filter the data as per the request 
        FilteredallData.map((item,i)=>{FetchedData[kwords[i]]=item.data})
        //console.log(FetchedData)   alert here if fethced data is all empty
        //-----------------
        props.set_Additional_dataset_and_attribute_data(InitialAlgorithms.Additional_dataset_and_attribute_data(FetchedData))
        props.set_datasets_with_Attributes(MatrixAlgorithms.MatrixData(InitialAlgorithms.datasets_with_Attributes_dict(FetchedData),props.Min_numberofattribute_occurence).filteredDatasetswithAttributes)
        props.set_datasets_with_Attributes_original(InitialAlgorithms.datasets_with_Attributes_dict(FetchedData))
        props.set_categorizedDatasets(InitialAlgorithms.categorizedDatasets(FetchedData))
        props.set_datasetWithTagArray(InitialAlgorithms.datasetWithTagArray(FetchedData))
        props.set_dict_of_tags_basedon_keyword(InitialAlgorithms.dict_of_tags_basedon_keyword(FetchedData))
        props.set_tag_count(InitialAlgorithms.tag_count(FetchedData))
        props.set_datasets_link(InitialAlgorithms.datasets_link(FetchedData))
        props.setFetchedData(FetchedData)
      })
    )
  }
  React.useEffect(() => {
    fetchData()
  }, [])
//https://www.youtube.com/watch?v=Mt3kxUvHORA
//https://reactjs.org/docs/hooks-effect.html
//--------------------------------------------------
  const [anchorEl_pop, setAnchorEl_pop] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl_pop(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl_pop(null);
  };
  const open = Boolean(anchorEl_pop);
  const id = open ? 'simple-popover' : undefined;
  var inputHandler=(event)=>{
    setKeywords(event.target.value.split(','))
  }
  const [drawer_state, set_drawer_state] = React.useState(false);
  const toggleDrawer =(type) => {
    drawer_state==true?set_drawer_state(false):set_drawer_state(true);
  };
  var show_links=()=>{
    var datasets_with_Attributes_dict=MatrixAlgorithms.datasets_with_AttributesMaker_forTagsclicks(props.datasets_based_onTagclicks,props.datasets_with_Attributes_original)
    props.set_datasets_with_Attributes(datasets_with_Attributes_dict)
    //MatrixAlgorithms.MatrixData(this.props.datasets_with_Attributes_dict,this.props.Min_numberofattribute_occurence)
  }
//--------------------------------------- 
  return (
    <div className={classes.grow}>
      <AppBar position="static" classes={{root:classes.myAppBar}}>
        <Toolbar className={classes.myToolbar}>
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="open drawer" onClick={toggleDrawer}>
            <MenuIcon />
          </IconButton>
          <div className={classes.keywords}>
            <InputBase placeholder="keywords…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'keywords' }}
              onChange={event=>inputHandler(event)}
            />
<Button className={classes.mybuttons+" "+classes.portalButtons}  size="medium"  onClick={handleClick}>Set Portals</Button>
          </div>
{// ----------------Popover
}
<Popover classes={{paper:classes.portalpop}} id={id} open={open} anchorEl={anchorEl_pop} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center',}} transformOrigin={{vertical: 'top', horizontal: 'center',}}>
<Portals></Portals>
</Popover>
{// ----------------Popover
}
    <Button className={classes.mybuttons+" "+classes.searchButton}  size="medium" onClick={()=>{props.set_clicked_tag_Array([]);fetchData()}}>Search dataset</Button>
    {
    //props.datasets_based_onTagclicks!=null?<Button className={classes.mybuttons+" "+classes.searchButton}  size="medium" onClick={()=>show_links()}>Show links</Button>:null
    }
        </Toolbar>
      </AppBar>
      <Drawer anchor={'left'} open={drawer_state} onClose={toggleDrawer}>
       <Mydrawer></Mydrawer>
      </Drawer>
    </div>
  );
}
const maptstateToprop=(state)=>{
  return{
    ctr:state.counter,
    MaxnumberofDatasetTofetch:state.MaxnumberofDatasetTofetch,
    FetchedData:state.FetchedData,
    Addedportals:state.Addedportals,
    datasets_based_onTagclicks:state.datasets_based_onTagclicks,
    datasets_with_Attributes_original:state.datasets_with_Attributes_original,
    Min_numberofattribute_occurence:state.Min_numberofattribute_occurence,
    clicked_tag_Array:state.clicked_tag_Array,
  }
}
const mapdispatchToprop=(dispatch)=>{
  return{
    add:(val)=>dispatch({type:"add",value:val}),
    setFetchedData:(d)=>dispatch({type:"FetchedData",value:d}),
    set_dict_of_tags_basedon_keyword:(d)=>dispatch({type:"dict_of_tags_basedon_keyword",value:d}),
    set_datasetWithTagArray:(d)=>dispatch({type:"datasetWithTagArray",value:d}),
    set_datasets_with_Attributes_original:(d)=>dispatch({type:"datasets_with_Attributes_original",value:d}),
    set_categorizedDatasets:(d)=>dispatch({type:"categorizedDatasets",value:d}),
    set_tag_count:(d)=>dispatch({type:"tag_count",value:d}),
    set_datasets_with_Attributes:(d)=>dispatch({type:"datasets_with_Attributes_dict",value:d}),
    set_keyword_colors:(d)=>dispatch({type:"keyword_colors",value:d}),
    set_datasets_based_onTagclicks:(d)=>dispatch({type:"datasets_based_onTagclicks",value:d}),
    set_Additional_dataset_and_attribute_data:(d)=>dispatch({type:"Additional_dataset_and_attribute_data",value:d}),
    set_clicked_tag_Array:(d)=>dispatch({type:"clicked_tag_Array",value:d}),
    set_datasetdescriptionDict:(d)=>dispatch({type:"datasetdescriptionDict",value:d}),
    set_datasets_link:(d)=>dispatch({type:"datasets_link",value:d}),
  }
}
export default connect(maptstateToprop,mapdispatchToprop)(Header);
//------------------------------------------------------------------------------------------------------------------------Styles
const useStyles = makeStyles((theme) => ({
    myAppBar:{
      backgroundColor:"#f2f2f2",
      color:"black",
      display:"relative",
      boxShadow:"0px 1px 3px 0px #999797",
    },
    mybuttons:{
      color:"#4f4d4d",
      borderRadius:"0px",
      lineHeight:1.3,
    },
    portalButtons:{
      backgroundColor:"rgb(226, 226, 226,.4)",
      borderLeft:"1px solid #f2efef",
    },
    searchButton:{
      marginLeft:"0px",
      borderLeft:"5px solid rgb(214, 214, 214,.15)",
      borderRight:"5px solid rgb(214, 214, 214,.15)",
      backgroundColor:"rgb(226, 226, 226,.4)",
    },
    myToolbar:{
      minHeight:"45px",
      Height:"45px",
    },
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    keywords: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor:"rgb(226, 226, 226,.2)",
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
      },
    },
    inputRoot: {
      color: 'inherit',
      
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: "10px",
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
    portalpop:{
      boxShadow:"none",
      borderRadius:"0px",
      top:"48px!important",
      border:"1px solid grey",
      borderTop:"none",
      padding:"20px",
      maxWidth:"95vw",
      maxHeight:'60vh',
      backgroundColor:"#F2F2F2",
    },

  }));
  