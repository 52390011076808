// myfunc generates keyword based datasets on tags clicks
export function myfunc(datasetWithTagArray,clicked_tag_Array,categorizedDatasets){
// following code is to make dataset groups based on keyword
  var dict_for_keyword_based_matrix_grouping={}
  for(var dataset in datasetWithTagArray){
    //--
    for(var tag_index in clicked_tag_Array){
    if(datasetWithTagArray[dataset].includes(clicked_tag_Array[tag_index])){
        for(var keyword in categorizedDatasets){
          if(categorizedDatasets[keyword].includes(dataset)){
            if(keyword in dict_for_keyword_based_matrix_grouping){
              if(!dict_for_keyword_based_matrix_grouping[keyword].includes(dataset)){dict_for_keyword_based_matrix_grouping[keyword].push(dataset)}
            }else{
              dict_for_keyword_based_matrix_grouping[keyword]=[dataset]
            }
          }
        }
    }
  }
  }  
  console.log(dict_for_keyword_based_matrix_grouping)
return dict_for_keyword_based_matrix_grouping;
}
//-----------------------------------------------------------------
export function MatrixData(datasets_with_Attributes_dict,Min_numberofattribute_occurence){
var min_attr_occur=Min_numberofattribute_occurence;
var tempFirst_bar_Arr={}
    for(var key1 in datasets_with_Attributes_dict){
        datasets_with_Attributes_dict[key1].map(item=>{
            if(tempFirst_bar_Arr[item]>0){tempFirst_bar_Arr[item]=tempFirst_bar_Arr[item]+1}
            else{tempFirst_bar_Arr[item]=1}
        })
    }
// Filter by count
    var tempFirst_bar_ArrFiltered={}
    for(var attr in tempFirst_bar_Arr){if(tempFirst_bar_Arr[attr]>=min_attr_occur){tempFirst_bar_ArrFiltered[attr]=tempFirst_bar_Arr[attr]}}

//-------------------------------------return only shared attributes filtered_shared_attributes
var filtered_shared_attributes=[]    
for(var key2 in tempFirst_bar_ArrFiltered){if(tempFirst_bar_ArrFiltered[key2]>=min_attr_occur){filtered_shared_attributes.push(key2)}}

//-------------------------------------return sorted attributes with highest to lowest frequency sorted_Atrributes
var sorted_Atrributes;
var items = Object.keys(tempFirst_bar_ArrFiltered).map(function(key3) {
    return [key3, tempFirst_bar_ArrFiltered[key3]];
  });
  items.sort(function(first, second) {
    return second[1] - first[1];
});
sorted_Atrributes=items.map(item=>item[0])
//-------------------------------------return the attributes in first array and their occurence in second
var first_bar_Arr=[items.map(item=>item[0]),items.map(item=>item[1])]
//-------------------------------------
var unionA=tempFirst_bar_ArrFiltered;
//-------------------------------------
var filteredDatasetswithAttributes={}

Object.keys(tempFirst_bar_ArrFiltered).filter(item=>{
  Object.keys(datasets_with_Attributes_dict).filter(item2=>{
    if(datasets_with_Attributes_dict[item2].includes(item)){filteredDatasetswithAttributes[item2]=datasets_with_Attributes_dict[item2]}
  })
})
return {first_bar_Arr:first_bar_Arr,filtered_shared_attributes:sorted_Atrributes,unionA:unionA,filteredDatasetswithAttributes:filteredDatasetswithAttributes};    
}

export function datasets_with_AttributesMaker_forTagsclicks(filteredDatasets,datasets_with_Attributes_dict){
  var temp={}
  for(var keyword in filteredDatasets){
  filteredDatasets[keyword].map(item=>{
      temp[item]=datasets_with_Attributes_dict[item]    
})
}
return temp
} 