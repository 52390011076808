
// this function is used to generate the matrix for initial visualization
export function matrixgen(only_shared_attributes,datasets_in_dictionary){
    var datasets=[];
    var matrix=new Array(Object.keys(datasets_in_dictionary).length);
    var count=0;
    for (var combinations_key in datasets_in_dictionary){
        datasets.push(combinations_key);
        matrix[count]=new Array(only_shared_attributes.length);
        for(var combination_index=0;combination_index<only_shared_attributes.length;combination_index++){
            for(var j=0;j<datasets_in_dictionary[combinations_key].length;j++){
                if(only_shared_attributes[combination_index]==datasets_in_dictionary[combinations_key][j]){
                matrix[count][combination_index]=1;
            }
            else{
                if(!matrix[count][combination_index]==1){
                    matrix[count][combination_index]='n';
                }
            }
        }
    }
    count++;
    }
    /* This is how the matrix looks like
    0: (6) [1, 1, 1, "n", "n", "n"]
    1: (6) [1, 1, 1, "n", "n", "n"]
    2: (6) [1, 1, 1, "n", "n", "n"]
    3: (6) [1, "n", 1, "n", 1, "n"]
    4: (6) [1, "n", 1, "n", 1, "n"]
    5: (6) [1, "n", 1, "n", 1, "n"]
    6: (6) [1, 1, "n", 1, "n", "n"]
    7: (6) [1, 1, "n", 1, "n", "n"]
    8: (6) [1, 1, "n", 1, "n", "n"]
    9: (6) ["n", 1, "n", 1, "n", 1]
    1: (6) ["n", 1, "n", 1, "n", 1]
    1: (6) ["n", 1, "n", 1, "n", 1]
    */
    //console.log(matrix)
    return {'datasets':datasets,'matrix':matrix}
    }
    //----------------------------------------------------The start of grouping
    var combination_temp_arr=[]
    var combination=[]
    export function generateAllBinaryStrings(n,  arr, i){
        if (i == n)  
        {
            combination_temp_arr=[] 
            for (i = 0; i < n; i++)  
            { 
                combination_temp_arr.push(arr[i])
            }
            combination.push(combination_temp_arr) 
        return; 
        } 
        arr[i] = 0; 
        generateAllBinaryStrings(n, arr, i + 1); 
        arr[i] = 1; 
        generateAllBinaryStrings(n, arr, i + 1);
        if(2**n==combination.length){
            console.log(combination)
            return combination
        }
    }
    //------------------------------------------------------------------------------------------------------ Matrix3 generator starts here
    export function matrixgen3(only_shared_attributes,datasets_in_dictionary){ // this matrix is used for grouping
        combination_temp_arr=[]
        combination=[]
        var matrix={}
        var count=0;
        for (var datasets_from_datasets_in_dictionary in datasets_in_dictionary){
            matrix[datasets_from_datasets_in_dictionary]=new Array(only_shared_attributes.length);
            for(var combination_index=0;combination_index<only_shared_attributes.length;combination_index++){
                for(var j=0;j<datasets_in_dictionary[datasets_from_datasets_in_dictionary].length;j++){
                    if(only_shared_attributes[combination_index]==datasets_in_dictionary[datasets_from_datasets_in_dictionary][j]){
                   //console.log(matrix[count]);
                    matrix[datasets_from_datasets_in_dictionary][combination_index]=1;
                    }
                else{
                    //matrix[count][combination_index]='n';
                    if(!matrix[datasets_from_datasets_in_dictionary][combination_index]==1){
                        matrix[datasets_from_datasets_in_dictionary][combination_index]=0;
                    }
                }
            }
        }
        count++;
        }
        return matrix
        }
    //------------------------------------------------------- Grouping function to generate Groups
    export function grouping_based_on_combination3(combination,matrix,clicked_attributes){
        var testdict={}
        for(var i=1;i<combination.length;i++){
              for (var key in matrix) {
                if(check(matrix[key],combination[i],clicked_attributes)==1){
                    if(testdict[JSON.stringify(combination[i])]){
                        var test_arr=testdict[JSON.stringify(combination[i])]
                        test_arr.push(key)
                        testdict[JSON.stringify(combination[i])]=test_arr
                    }
                    else{
                        var test_arr=[]
                        test_arr.push(key)
                        testdict[JSON.stringify(combination[i])]=test_arr
                    }
                }
            }
        }
        return Object.values(testdict)
    }
    //------------------------------------------------------- Check function to check wheter the combination exists
    export function check(row,combination,attributes){
        var arr=[]
        for(var i=0;i<attributes.length;i++){  
            if(row[attributes[i]]==combination[i]){
                arr.push(combination[i])   
            }
        }
        if(JSON.stringify(arr)==JSON.stringify(combination)){
            return 1;
        }
    }