import React, { Component } from 'react'
import * as d3 from 'd3';
class ParentHeatmap extends Component {
   constructor(props){
      super(props)
      this.childKey = 0;
      this.createHeatMap = this.createHeatMap.bind(this)
      this.prep = this.prep.bind(this)
      this.X_charsize=50;
      this.dataset_bar_distance=1;
   }
    shouldComponentUpdate(nextProps, nextState) {
      return ((this.props.categorizedDatasets!==nextProps.categorizedDatasets)) || (JSON.stringify(this.props.matrixdata)!==JSON.stringify(nextProps.matrixdata));
  }
//--------------------------------Prepare for HeatMap here
   prep(mydata,filtered_shared_attributes){
       
    var arr=[]
    for (var i=0;i<mydata.datasets.length;i++){
        for(var j=0;j<filtered_shared_attributes.length;j++){
            var arr2=[];
            arr2.push(mydata.datasets[i]);
            arr2.push(filtered_shared_attributes[j])
            arr2.push(mydata.matrix[i][j]);
            arr.push(arr2);
        }
    }
    return arr;
}
   //------------CreateHeatMap starts here
createHeatMap() {
  var animation_duration=1000
  var multiplier=20; // multiplier defines the cell size
  var myWidth=(this.props.filtered_shared_attributes.length*multiplier) + 520+multiplier
  var myHeight=(this.props.matrixdata.datasets.length*multiplier) + 10+multiplier  
//---------------------------------------------------------------------------------
     var self=this;
    //const node = this.node
    const node = "."+this.props.myclass
    // change the left margin so that dataset names are visible
      var margin = {top: 10, right: 140, bottom: 20, left: 400};
    var width = myWidth - margin.right - margin.left,
    height = myHeight - margin.top - margin.bottom;
    var data=this.prep(this.props.matrixdata,this.props.filtered_shared_attributes)
    var cloned_data = JSON.parse(JSON.stringify(data))
    // duplicate values creates problem when trancated
    var x_elements = d3.set(cloned_data.map(function( d ) { 
      return d[1].substring(0,self.X_charsize); } )).values();
    var y_elements = d3.set(data.map(function( d ) { return d[0] } )).values();
// Ordinal Scaling for X axis
    var xScale = d3.scaleBand()
      xScale.domain(x_elements).range([0, width]);
    var xAxis = d3.axisTop(xScale)
      .tickFormat(function (d) {
          return d;
      })
      //const x = d3.scaleLinear();
      //const xAxis = d3.axisBottom(x);
//Ordinal Scaling for Y axis
    var yScale = d3.scaleBand()
      .domain(y_elements)
      .range([0, height]);
    var yAxis = d3.axisLeft(yScale)
      .tickFormat(function (d) {
          return d;
      })
      
//-------------------------------------------------------------------------------------- d3 Select starts here
    var svg = d3.select(node).attr("width", width + margin.left + margin.right).attr("height", height + margin.top + margin.bottom)
    var gMatrix=d3.select(node).selectAll('.group_xy_axis'+this.props.myclass).data([null])
    gMatrix=gMatrix.enter().append('g').merge(gMatrix).attr("class","group_xy_axis"+this.props.myclass).attr("transform", "translate(" + margin.left + "," + margin.top + ")"); // Append only one since the array contains 1 element
    var gMatrixData=gMatrix.selectAll('rect').data(data)
    var gMatrixExit=gMatrixData.exit().remove() // make sure to call the data
    var gMatrixEnter=gMatrixData.enter() // make sure to call the data
    gMatrixData.merge(gMatrixEnter)

//-------------------------------------------------------------------------------------- Main view starts here
          var rects=gMatrixData.enter().append('rect')
          .merge(gMatrixData)          
          .attr('class', 'cell')
          .attr('id',(d,i)=>"cell"+i)
          .attr('width', width/(this.props.filtered_shared_attributes.length)-1)
          .attr('height', height/(this.props.matrixdata.datasets.length)-1)
          .attr('y', function(d) { return yScale(d[0]); })
          .transition().duration(animation_duration)
          .attr('x', function(d) { return xScale(d[1].substring(0,self.X_charsize)); })
          .attr('fill', (d,i)=>{
              return d[2]!=='n'? 'rgb(158,154,200)':"#bdbdbd";
          })
          .attr('fill', (d,i)=>{
            if(this.props.categorizedDatasets!= null){
              //d[0] returns datasetname for each cell
              var temp_color;
              var self=this;
              Object.keys(this.props.categorizedDatasets).forEach(function(k, i) {
                if(self.props.categorizedDatasets[k].includes(d[0])){
                temp_color=self.props.cat_colors[k];
                }
            });
              return d[2]!=='n'? temp_color:"#bdbdbd";
            }
            else{
              return d[2]!=='n'? 'rgb(158,154,200)':"#bdbdbd";
            }
            
          })
//------------------------------------------- Datasets Bar start here (This is for the left barchart)
var marginLeft=margin.left
var number_of_attr_in_dataset=[]
var dataset_names=[]
for(var key in this.props.dataset_bar_data){
  number_of_attr_in_dataset.push(this.props.dataset_bar_data[key].length)
  dataset_names.push(key)
}
console.log(dataset_names)
var xScale_for_datasets_bar = d3.scaleLinear()
  .domain([d3.min(number_of_attr_in_dataset),d3.max(number_of_attr_in_dataset)])
  .range([20, marginLeft - 320]);
//-----rectangles
var svg_datasets_bars_container=svg.selectAll('.Ysvg'+this.props.myclass).data([0])
svg_datasets_bars_container.exit().remove()
svg_datasets_bars_container=svg_datasets_bars_container.enter().append("svg").merge(svg_datasets_bars_container)
.attr("class", "Ysvg"+this.props.myclass).attr("x",0).attr("y",margin.top).attr("width",marginLeft).attr("transform", "translate(" + -6 + "," + 0 + ")");

var YrectBars=svg_datasets_bars_container.selectAll('rect').data(number_of_attr_in_dataset)
YrectBars.exit().remove()
YrectBars=YrectBars.enter().append('rect').merge(YrectBars)
.attr('x',(d)=> (margin.left - xScale_for_datasets_bar(d))+xScale_for_datasets_bar(d))
.attr("width",(d)=>xScale_for_datasets_bar(d)-this.dataset_bar_distance) // -2 makes the bars 2 unit further
.attr('x',(d)=> margin.left - (xScale_for_datasets_bar(d)-0))
.attr("height",height/(this.props.matrixdata.datasets.length)-1)
.attr("fill","#5f89ad")
.transition()
.duration(animation_duration)
.attr("y",(d,i)=>yScale(dataset_names[i]))
//-----frequency text
var Ytext=svg_datasets_bars_container.selectAll('text').data(number_of_attr_in_dataset)
Ytext.exit().remove()
Ytext.enter().append('text').merge(Ytext)
.attr('x',(d,i)=>marginLeft - (15+d.toString.length*5))
.attr("font-size",12)
.transition()
.duration(animation_duration)
.attr("y",(d,i)=>yScale(dataset_names[i])+15)
.text((d)=>d)
.attr("fill","white")
//-Datasets Bar ends here
//------------------------------------------- Y Axis
const Yaxisgroup = svg.selectAll('.myY'+this.props.myclass).data([0]) // g has all the updated data 
Yaxisgroup.exit().remove()
Yaxisgroup.enter().append("g").merge(Yaxisgroup)
.attr("class", "myY"+this.props.myclass+" y axis").attr("transform", "translate(" + margin.left + "," + margin.top + ")") // Append only one since the array contains 1 element
.transition().duration(animation_duration).call(yAxis).selectAll('text').attr("x",-82).text((d,i)=>d.length>50?d.substring(0,50)+'...':d)
d3.select('.myY'+this.props.myclass).selectAll('text')
      .on('click',(d,i)=> {
        this.props.xy_axis_clickhandler(d3.event,d,'index','y')
        })
      .on('contextmenu',(d,i)=> {
        this.props.contextmenuHandler(d3.event,"download") 
        })
      .attr("cursor", "pointer")
      .attr("id",(d)=> d);
//----- Y axis ends here
d3.selectAll('.domain,.tick line').remove()  
var legend=svg.append('svg')
//Border for parent
//gMatrix.append("rect").attr("x", 0).attr("y", 0).attr("height", height).attr("width", width).style("stroke","#0570b0").style("fill", "none").style("stroke-width", 3);

}
//CreateMap ends here
componentDidMount() {
  this.createHeatMap()
}
componentDidUpdate() {
  this.createHeatMap()
}
render() 
{
    return(
      <div style={{display:"block",minWidth:'100%'}}><svg className={this.props.myclass} key={this.props.myclass}></svg></div>
    )
}
}
export default ParentHeatmap