import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {connect} from "react-redux";
import * as InitialAlgorithms from "../Algorithms/InitialAlgorithms";
import * as MatrixAlgorithms from "../Algorithms/MatrixAlgorithms";
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop:45,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

function Mydrawer(props) {
  const classes = useStyles();
  function InputHandler(event,type){
    if(type=="dataset"){
    props.set_MaxnumberofDatasetTofetch(event.target.value)
    }
    else if(type=="attribute"){
      props.set_Min_numberofattribute_occurence(event.target.value)
      props.set_datasets_with_Attributes(MatrixAlgorithms.MatrixData(InitialAlgorithms.datasets_with_Attributes_dict(props.FetchedData),event.target.value).filteredDatasetswithAttributes)
    }
  }
  return (
    <div className={classes.root}>
      <div>
        <TextField
          id="standard-full-width"
          label="Max number of Datasets per Keyword"
          style={{ margin: 8 }}
          placeholder="Placeholder"
          defaultValue={props.MaxnumberofDatasetTofetch}
          fullWidth
          margin="normal"
          onChange={(event)=>InputHandler(event,"dataset")}
          InputLabelProps={{
            shrink: true,
          }}
        />

        <TextField
          id="standard-full-width"
          label="Max attribute occurence"
          style={{ margin: 8 }}
          placeholder="Placeholder"
          defaultValue={props.Min_numberofattribute_occurence}
          fullWidth
          margin="normal"
          onChange={(event)=>InputHandler(event,"attribute")}
          InputLabelProps={{
            shrink: true,
          }}
        />

      </div>
    </div>
  );
}
const maptstateToprop=(state)=>{
  return{
    MaxnumberofDatasetTofetch:state.MaxnumberofDatasetTofetch,
    Min_numberofattribute_occurence:state.Min_numberofattribute_occurence,
    FetchedData:state.FetchedData,
  }
}
const mapdispatchToprop=(dispatch)=>{
  return{
    set_MaxnumberofDatasetTofetch:(d)=>dispatch({type:"MaxnumberofDatasetTofetch",value:d}),
    set_Min_numberofattribute_occurence:(d)=>dispatch({type:"Min_numberofattribute_occurence",value:d}),
    set_datasets_with_Attributes:(d)=>dispatch({type:"datasets_with_Attributes_dict",value:d}),
  }
}
export default connect(maptstateToprop,mapdispatchToprop)(Mydrawer);