let initialState={
    counter:10,
    MaxnumberofDatasetTofetch:30,
    Min_numberofattribute_occurence:3,
    keyword_colors:{},
    FetchedData:null,
    dict_of_tags_basedon_keyword:null,
    tag_count:null,
    datasetWithTagArray:null,
    categorizedDatasets:null,
    datasets_with_Attributes_original:null,
    datasets_with_Attributes_dict:null,
    Addedportals:["New York State Data portal"],
    filtered_datasetWithTagArray:null,
    filtered_categorizedDatasets:null,
    datasets_based_onTagclicks:null,
    Additional_dataset_and_attribute_data:null,
    clicked_tag_Array:['education','school','housing','hpd'],
    datasets_link:null,
    clicked_datasets:[],
    merge_state:false,

}
const reducer=(state=initialState,action)=>{
    if(action.type==="add"){
        return {counter:state.counter+action.value}
    }
    if(action.type==="FetchedData"){
        return {...state,FetchedData:action.value}
    }
    if(action.type==="dict_of_tags_basedon_keyword"){
        return {...state,dict_of_tags_basedon_keyword:action.value}
    }
    if(action.type==="tag_count"){
        return {...state,tag_count:action.value}
    }
    if(action.type==="datasetWithTagArray"){
        return {...state,datasetWithTagArray:action.value}
    }
    if(action.type==="categorizedDatasets"){
        return {...state,categorizedDatasets:action.value}
    }
    if(action.type==="datasets_with_Attributes_original"){
        return {...state,datasets_with_Attributes_original:action.value}
    }
    if(action.type==="datasets_with_Attributes_dict"){
        return {...state,datasets_with_Attributes_dict:action.value}
    }
    if(action.type==="Addportals"){
        return {...state,Addedportals:action.value}
    }
    if(action.type==="keyword_colors"){
        return {...state,keyword_colors:action.value}
    }
    if(action.type==="MaxnumberofDatasetTofetch"){
        return {...state,MaxnumberofDatasetTofetch:action.value}
    }
    if(action.type==="Min_numberofattribute_occurence"){
        return {...state,Min_numberofattribute_occurence:action.value}
    }
    if(action.type==="datasets_based_onTagclicks"){
        return {...state,datasets_based_onTagclicks:action.value}
    }
    if(action.type==="Additional_dataset_and_attribute_data"){
        return {...state,Additional_dataset_and_attribute_data:action.value}
    }
    if(action.type==="clicked_tag_Array"){
        return {...state,clicked_tag_Array:action.value}
    }
    if(action.type==="datasets_link"){
        return {...state,datasets_link:action.value}
    }
    if(action.type==="clicked_datasets"){
        return {...state,clicked_datasets:action.value}
    }
    if(action.type==="merge_state"){
        return {...state,merge_state:action.value}
    }
    //clicked_datasets
return state;
}
export default reducer;