import React, { Component } from 'react';
import './css/treemap.css'
import {Row,Col} from 'reactstrap';
import Grid from '@material-ui/core/Grid';
import {connect} from "react-redux";
class Treemap extends Component {
  constructor(props) {
  super(props);
  this.state = {keywords:null,keywords_tags_dictionary:null,sorted_tag:null};
  this.createTreemap=this.createTreemap.bind(this)
}
createTreemap(){
//-------------------
  var sorted_tag=this.sort_object(this.props.tag_count)
  this.setState({sorted_tag:sorted_tag})
//-------------------
    var mydata=this.props.mydata
    var keywords=Object.keys(mydata)

    this.setState({keywords:keywords})
    this.setState({keywords_tags_dictionary:mydata})
}
// sorts javascript dictionary and returns as a two dimensional array
sort_object=(dict)=> {
    var items = Object.keys(dict).map(function(key) {
      return [key, dict[key]];
    });
    items.sort(function(first, second) {
      return second[1] - first[1];
    });
  return items;  
} 
//----------------------
componentDidMount() {
    this.createTreemap()
  }
  componentDidUpdate() {
    this.createTreemap()
  }
  shouldComponentUpdate(nextProps, nextState){
    if(this.state.keywords==null){
       return true;
     }
     else{
         return false
     }
    }
//-----------------------------------------------------------------Render function starts here  
render() {
  return (
  <div style={{width:'100%'}}>
  <Grid container className="char_list_container2" spacing={1}>
      {this.state.keywords!==null?this.state.keywords.map((key)=>{
        var i=0;
        var max_count=60/this.state.keywords.length
        var count=1;
        var mycolors=this.props.colors;
         var keywords_tags_dictionary=this.state.keywords_tags_dictionary
         var split=Math.floor(12/Object.keys(keywords_tags_dictionary).length);
         var mydata=keywords_tags_dictionary[key]
         var mysorted_tags=this.state.sorted_tag;
         var max_occurence=mysorted_tags[0][1];
         var char_length_of_max=mysorted_tags[0][0].length;
        return <Grid item xs key={key}>{mysorted_tags.map((dd)=>
        {
          if(mydata.includes(dd[0])){
            var d=dd[0]
            if(d.length<10){ var p_width=100;}else{var p_width=d.length*7;}
            var depth=(100/max_occurence)*dd[1];
            if(i===0){
              var a=[]
              a.push(<p className="tag_keyword" key={d+1} style={{backgroundColor:mycolors[key]}}>{key}</p>)
              a.push(<p style={{background: 'linear-gradient(90deg, '+mycolors[key]+' '+depth+'px'+', transparent 0px)',width:p_width}} className={this.props.clicked_tag_Array.includes(d)?"all_chars treemap_class":"all_chars"}
              id={d} key={d} onClick={(e)=>this.props.tag_clickhandler(d)}>{d}</p>)
              i=i+1
              return a
            }
            else{
              if(count<max_count){
                count=count+1
                return <p style={{background: 'linear-gradient(90deg, '+mycolors[key]+' '+depth+'px'+', transparent 0px)',width:p_width}} className={this.props.clicked_tag_Array.includes(d)?"all_chars treemap_class":"all_chars"}
                id={d} key={d} onClick={(e)=>this.props.tag_clickhandler(d)}>{d}</p>
              }
          
            }
          }
        }
        )}</Grid>
      }):null}
  </Grid>
  </div>
  );
  }
}
const maptstateToprop=(state)=>{
  return{clicked_tag_Array:state.clicked_tag_Array,}
}
export default connect(maptstateToprop)(Treemap);
