import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import {connect} from "react-redux";
function getModalStyle() {
  const top = 30;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  
}));
function SimpleModal(props) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(props.merge_state);

  const handleOpen = () => {
    props.set_merge_state(true);
  };

  const handleClose = () => {
    props.set_merge_state(false);
  };
  const make_copy=(myurl)=> {
    alert(myurl)  
  }

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h2>Datasets selected</h2>
      {props.clicked_datasets.map(item=><a style={{textDecoration:"none",color:"black"}} href={props.datasets_link[item]+".csv"}><p onClick={()=>{make_copy(props.datasets_link[item]+".csv")}}>{item}</p></a>)}
      {
        <a style={{textDecoration:"none",padding:"5px",marginLeft:"15px",border: "0px solid black",backgroundColor:"#303F9F",color:"white",cursor: "pointer"}} variant="contained" color="primary"  onClick={handleClose}>Cancel</a>
      }
    </div>
  );
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}

const maptstateToprop=(state)=>{
  return{
    merge_state:state.merge_state,
    clicked_datasets:state.clicked_datasets,
    datasets_link:state.datasets_link,
  }
}
const mapdispatchToprop=(dispatch)=>{
  return{
    set_merge_state:(d)=>dispatch({type:"merge_state",value:d}),
  }
}
export default connect(maptstateToprop,mapdispatchToprop)(SimpleModal);
